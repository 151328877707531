import React, { useEffect } from "react"
import { getSearchParams } from "gatsby-query-params"
import Layout from "../Components/Layout"
import Box from "../Components/Box"
import { RaisedButton } from "../Components/Buttons"
import beginIlliustration from "../assets/images/begin-illiustration.jpg"

export default () => {
  const params = getSearchParams()

  useEffect(() => {
    localStorage.setItem("token", params.t)
  }, [params])

  return (
    <Layout>
      <div className="begin-page">
        <Box>
          <div className="begin-page--intro tac">
            <h1>Leiskis į 2020 MOdisėją!</h1>

            {/*<div className="text-container">
              <p>
                Pasiruošęs? <br />
                <br />
                MOlimpiada jau tuoj prasidės! <br />
                <br />
                Primename, kad visi klausimai turi tik vieną teisingą atsakymą.
                <br />
                Rezultatus atsiųsime el. paštu žaidynėms pasibaigus. <br />
                Penki daugiausiai teisingų atsakymų per trumpiausią laiką
                surinkę dalyviai bus apdovanoti specialiais MO prizais.
                <br />
                <br />
                Bet svarbiausia ne laimėti, o dalyvauti! <br />
                <br />
                Taigi pradėk - sėkmės!
              </p>
            </div>

            <RaisedButton to="/test">Pradėti molimpiadą</RaisedButton>*/}


            <div className="text-container">
              <p>
                3…2…1
                <br/>
                Kai tik būsi pasiruošęs, drąsiai spausk „Pradėti“ – netrukus pradėsi treniruotę, išbandydamas MOdisėjos 2020 klausimus!
                <br />
                <br />
                <br />
                <br />

              </p>
            </div>

            <RaisedButton to="/try-test">PRADĖTI</RaisedButton>


          </div>
        </Box>
      </div>

      <div className="tac">
        <img src={beginIlliustration} alt="" />
      </div>
    </Layout>
  )
}
